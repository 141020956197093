import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import './Footer.css';

class Footer extends Component {

    state = {
        currentPage: '',
        styles: {
            borderColor: 'white',
            color: 'white',
        }
    };

    componentDidMount(){
        const { location : { pathname } } = this.props.history;

        if(pathname === '/'){
            this.setState({
                currentPage: 'homePage',
                styles: {
                    borderColor: 'white',
                    color: 'white',
                }
            })
        }
        if(pathname === '/register'){
            this.setState({
                currentPage: 'registerPage',
                styles: {
                    borderColor: 'white',
                    color: 'white',
                }
            })
        }
        if(pathname === '/services'){
            this.setState({
                currentPage: 'servicesPage',
                styles: {
                    borderColor: 'black',
                    color: 'black',
                }
            })
        }
        if(pathname === '/clients'){
            this.setState({
                currentPage: 'clientsPage',
                styles: {
                    borderColor: 'black',
                    color: 'black',
                }
            })
        }
        if(pathname === '/about'){
            this.setState({
                currentPage: 'aboutPage',
                styles: {
                    borderColor: 'black',
                    color: 'black',
                }
            })
        }
        if(pathname === '/terms'){
            this.setState({
                currentPage: 'termsPage',
                styles: {
                    borderColor: 'black',
                    color: 'black',
                }
            })
        }
        if(pathname === '/privacy'){
            this.setState({
                currentPage: 'privacyPage',
                styles: {
                    borderColor: 'black',
                    color: 'black',
                }
            })
        }
        if(pathname === '/cookies'){
            this.setState({
                currentPage: 'cookiesPage',
                styles: {
                    borderColor: 'black',
                    color: 'black',
                }
            })
        }
        
    }

    render(){
        const { color } = this.state.styles;
        const { currentPage } = this.state;
        const styles = { color: color };

        return(
            <div className={`footer ${currentPage}`}>
                <nav className="footerPageHolder">
                    <li className="footerItem">
                        <NavLink
                            style={styles}
                            activeClassName={'isActive'}
                            className="footerNavLink"
                            to='/terms'>
                            Terms
                        </NavLink>
                    </li>
                    <li className="footerItem">
                        <NavLink
                            style={styles}
                            activeClassName={'isActive'}
                            className="footerNavLink"
                            to='/privacy'>
                            Privacy
                        </NavLink>
                    </li>
                    <li className="footerItem">
                        <NavLink
                            style={styles}
                            activeClassName={'isActive'}
                            className="footerNavLink"
                            to='/cookies'>
                            Cookies
                        </NavLink>
                    </li>
                </nav>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return {
        user,
        users
    };
}

const connectedFooter = connect(mapStateToProps)(withRouter(Footer));
export { connectedFooter as Footer };
