import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { TopBar, Footer } from '../../components';

import './AboutPage.css';
const concordImg = require('./concord.webp');
const secretlyImg = require('./secretly.png');
const feedVaxImg = require('./FeedVax.png');
const foundationImg = require('./foundation.png');
const hopelessImg = require('./hopeless.jpeg');
const mushroomGroupImg = require('./mushroom_group.png');
const secretCityImg = require('./secret_city.png');
const reservoirImg = require('./reservoir.png');
const stonesTrowImg = require('./stones_throw.png');

class AboutPage extends Component {

    state = {
        divClass: 'aboutContent',
    };

    componentDidMount(){
        this.fadeIn();
    }

    fadeIn = () => {
        setTimeout(() => {
            this.setState({
                divClass: 'aboutContent active'
            })
        }, 500);
    };

    render() {
        const { history } = this.props;
        return (
            <div className={this.state.divClass}>
                <MetaTags>
                    <title>Entertainment Intelligence | About Page</title>
                    <meta name="description" content="Entertainment Intelligence" />
                    <meta property="og:title" content="Entertainment Intelligence" />
                    <meta property="og:image" content="./og-ei.jpg" />
                </MetaTags>
                <TopBar history={history} color={'black'} />
                <div className="aboutTextHolder">
                    <div>
                        <p className="aboutText">Entertainment Intelligence (Ei) provides enterprise-level data and analytics solutions to the music industry. </p>
                        <p className="aboutText">Empowering customers with a scalable platform to manage and action their data.</p>
                    </div>
                    <div className="partnerslist">
                        <div className="partnersItem">
                            <img src={concordImg} />
                        </div>
                        <div className="partnersItem">
                            <img src={secretlyImg} />
                        </div>
                        <div className="partnersItem">
                            <img src={secretCityImg} />
                        </div>
                        <div className="partnersItem">
                            <img src={feedVaxImg} />
                        </div>
                        <div className="partnersItem">
                            <img src={hopelessImg} />
                        </div>
                        <a href="https://www.reservoir-media.com/" target="_blank" className="partnersItem">
                            <img src={reservoirImg} />
                        </a>
                        <a href="https://www.stonesthrow.com/" target="_blank" className="partnersItem">
                            <img src={stonesTrowImg} />
                        </a>
                    </div>
                </div>
                <Footer color={'black'} history={history} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedAboutPage = connect(mapStateToProps)(AboutPage);
export {connectedAboutPage as AboutPage};
