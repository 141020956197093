
const RECAPTCHA_SECRET_KEY = '6Ld_gvkUAAAAAIPk-Ojj8ziOsfkhg4bgTpLIHaqT';
const PROXY_URL = 'https://cors-anywhere.herokuapp.com/';
const SEND_TOKEN_URL = 'https://www.google.com/recaptcha/api/siteverify';
const POSITIVE_SCORE = 0.5;

function checkCaptcha (params) {
    // const url = `${SEND_TOKEN_URL}?secret=${RECAPTCHA_SECRET_KEY}&response=${params}`;

    const url = 'https://orthus.entertainment-intelligence.com/api/v1/recaptcha'

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({'g-recaptcha-response-data': params}),
        headers: {
            'Content-Type': 'application/json'
        }
    })
      .then(response => response.json())
      .then((contents) =>{
        if(contents.status === 'ok'){
          return true;
        }else{
          return false;
        }})
      .catch(e => {
          return (e);
      });
};

export const contactUsFormService = {
    checkCaptcha,
};
