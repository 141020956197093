import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TopBar, IconFacebook, IconLinkedIn, IconTwitter, IconEmail, Footer } from '../../components';
import MetaTags from 'react-meta-tags';

import Recaptcha from '../../components/Recaptcha/Recaptcha';

import { userActions } from '../../_actions';
import { updateValidators, resetValidators, displayValidationErrors, isFormValid } from '../../_helpers';

import './RegisterPage.css';
import {userConstants} from "../../_constants";
const RECAPTCHA_SITE_KEY = '6Ld_gvkUAAAAAEN2c3myjc2H9C_6EwiPDgENT5GJ';


class RegisterPage extends Component {
    state = {
        user: {
            email: '',
            company: '',
        },
        recaptchaToken: null,
        submitted: false,
        isValid: false,
        isEmpty: true,
        invalidMessage: '',
        divClass: 'registerContent',
        validators: {
            email: {
                rules: [
                    {
                        test: /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/,
                        message: 'Email is required'
                    }
                ],
                errors: [],
                valid: false,
                state: '',
            },
            company: {
                rules: [
                    {
                        test: (value) => {
                            return value.length > 0;
                        },
                        message: 'Company name is required'
                    }
                ],
                errors: [],
                valid: false,
                state: '',
            }
        },
        isRecaptcha: this.props.recaptcha,
    };

    handleLoaded = () => {
        if(typeof window !== 'undefined') {
            window.grecaptcha.ready(_ => {
                window.grecaptcha
                    .execute(RECAPTCHA_SITE_KEY, { action: "homepage" })
                    .then(token => {
                        this.setState({recaptchaToken: token})
                    })
            })
        }
    }

    componentDidMount(){
        this.fadeIn();
        resetValidators(this.state.validators);
    };

    componentDidUpdate(prevProps) {
        if (this.props.recaptcha !== prevProps.recaptcha) {
          this.setState({ isRecaptcha: this.props.recaptcha });
        }
      }

    fadeIn = () => {
        setTimeout(() => {
            this.setState({
                divClass: 'registerContent active'
            })
        }, 500);
    };


    handleChange = (event) => {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ submitted: true });
        const { dispatch } = this.props;
        const { user: { email, company } } = this.state;

        const formValues = { email, company };

        for (var key in formValues) {
            if (formValues.hasOwnProperty(key)) {
                updateValidators(this.state.validators, key, formValues[key]);
            }
        };

        const { recaptchaToken } = this.state;

        if(isFormValid(this.state.validators)) {
            dispatch(userActions.register(email, company, recaptchaToken));
        }
    };

    render() {
        const { history, registered, registerError  } = this.props;
        const { user, submitted, isRecaptcha } = this.state;
        return (
            <div className={this.state.divClass}>
                <MetaTags>
                    <title>Entertainment Intelligence | Subscribe Page</title>
                    <meta name="description" content="Entertainment Intelligence" />
                    <meta property="og:title" content="Entertainment Intelligence" />
                    <meta property="og:image" content="./og-ei.jpg" />
                </MetaTags>
                <TopBar history={history} color={'black'} />
                <h2>Register</h2>
                <div className="formHolder">
                    <h2 className="formTitle">Subscribe</h2>
                    {registerError ? (<div className='has-error has-error-big'>
                        {registerError}
                    </div>) : ('')}
                    {registered ? (
                        <div className='formSuccess'>
                            Thank you!
                        </div>
                    ) : (
                        <form className="Form" name="form" onSubmit={this.handleSubmit}>
                            <div className={'formGroup' + (submitted && !user.email ? ' hasError' : '')}>
                                <label className="formLabel" htmlFor="email">Email</label>
                                <input type="text" className="formControl" name="email" value={user.email} onChange={this.handleChange} />
                                { displayValidationErrors(this.state.validators, 'email') }
                            </div>
                            <div className={'formGroup' + (submitted && !user.email ? ' hasError' : '')}>
                                <label className="formLabel" htmlFor="company">Company Name</label>
                                <input type="text" className="formControl" name="company" value={user.company} onChange={this.handleChange} />
                                { displayValidationErrors(this.state.validators, 'company') }
                            </div>
                            <Recaptcha handleLoaded={this.handleLoaded} />
                            <div className="formGroup">
                                <button className={isRecaptcha ? 'Button' : 'Button'} >Subscribe</button>
                            </div>
                            <div className="iconsHolder">
                                <a className='iconLink' target='_blank' href="https://www.facebook.com/EntertainmentIntelligenceLtd/">
                                    <IconFacebook />
                                </a>
                                <a className='iconLink' target='_blank' href="https://www.linkedin.com/company/entertainment-intelligence-ltd">
                                    <IconLinkedIn />
                                </a>
                                <a className='iconLink' target='_blank' href="https://twitter.com/ent_intell">
                                    <IconTwitter />
                                </a>
                                <a className='iconLink' href="mailto:disruptors@ent-intell.com">
                                    <IconEmail />
                                </a>

                            </div>
                        </form>
                    )}
                    <p className="recaptchaMessage">
                        This site is protected by reCAPTCHA <br />
                        and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a>
                        <br /> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                    </p>

                </div>
                <Footer history={history} color={'black'} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering, registered, registerError } = state.registration;
    const { recaptcha } = state.contactUsForm;
    return {
        registering,
        registered,
        registerError,
        recaptcha,
    };
}

const connectedRegisterPage = connect(mapStateToProps)(RegisterPage);
export { connectedRegisterPage as RegisterPage };
