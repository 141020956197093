import React, { Component } from 'react';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';

import { TopBar } from '../../components';

import { clientsActions } from '../../_actions';

import './ClientsPage.css';

class ClientsPage extends Component {

    state = {
        currentClient: {
            id: null,
            name: '',
            url: '',
            bio: '',
            logo: '',
            silo: '',
        },
        activeItem: 0
    };

    componentDidMount(){
        this.fadeIn();
        const { dispatch } = this.props;
        dispatch(clientsActions.getAll());
    }

    componentWillReceiveProps(nextProps){
        const { clients } = nextProps;
        if(clients !== this.props.clients){
            const nextClients = clients.items ? clients.items.data : null;
            if(nextClients !== null){
                this.setState({
                    currentClient: {
                        id: nextClients[0].id,
                        name: nextClients[0].name,
                        url: nextClients[0].url,
                        bio: nextClients[0].bio,
                        logo: nextClients[0].logo,
                        silo: nextClients[0].silo,
                    },
                })
            }
        }
    }

    fadeIn = () => {
        setTimeout(() => {
            this.setState({
                divClass: 'clientsContent active'
            })
        }, 500);
    };

    onItemClick = (event, id) => {
        window.scrollTo( 0, 0 );
        const clients = this.props.clients.items.data;
        const currentClient = clients.filter(client => client.id === id);

        this.setState({
            currentClient: currentClient[0],
            activeItem: id
        })
    };

    renderClients = () => {
        const { clients } = this.props;
        const items = clients && clients.items ? clients.items.data : null;

        if(items !== null) {
            return items.map((client) => {
                return (
                    <li className={'clientsItem ' + (client.id === this.state.activeItem ? 'active' : '')}
                        key={client.id}
                        onClick={(e) => this.onItemClick(e, client.id)}>
                        {client.name}
                    </li>
                )
            })
        }
    };

    render() {
        const { history, clients } = this.props;
        const { currentClient, divClass } = this.state;
        const cutUrl =  currentClient.url.replace(/(^\w+:|^)\/\//, '');
        return (
            <div className={divClass}>
                <MetaTags>
                    <title>Entertainment Intelligence | Clients Page</title>
                    <meta name="description" content="Entertainment Intelligence" />
                    <meta property="og:title" content="Entertainment Intelligence" />
                    <meta property="og:image" content="./og-ei.jpg" />
                </MetaTags>
                <TopBar color={'white'} history={history} />
                <div className="contentHolder">
                    <ul className="clientsList">
                        {!clients.items ? (
                                <div className="spinners">
                                    <div className="spinner-block">
                                        <div className="spinner spinner-2"></div>
                                    </div>
                                </div>
                            ) : ('')}
                        {this.renderClients()}
                    </ul>
                    <div className="clientsDetails">
                        <div className="clientsLogoHolder">
                            {currentClient.logo && <img className="clientsLogo" src={currentClient.logo} alt={`Client ${currentClient.name} Logo`}/>}

                        </div>
                        <div className="clientsInfoHolder">
                            <p className="clientsBio">
                                {currentClient.bio}
                            </p>

                            <a className="clientsUrl" target='_blank' rel="noopener noreferrer" href={currentClient.url}>{cutUrl}</a>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    const { users, authentication, clients } = state;
    const { user } = authentication;
    return {
        user,
        users,
        clients
    };
}

const connectedClientsPage = connect(mapStateToProps)(ClientsPage);
export { connectedClientsPage as ClientsPage };
