import React from 'react';
import MetaTags from 'react-meta-tags';

import { TopBar } from '../../components'

import './NoMatchPage.css';

export const NoMatchPage = () => {
    return (
        <div className="noMatchContent">
            <MetaTags>
                <title>Entertainment Intelligence | 404 Page</title>
                <meta name="description" content="Entertainment Intelligence" />
                <meta property="og:title" content="Entertainment Intelligence" />
                <meta property="og:image" content="./og-ei.jpg" />
            </MetaTags>
            <TopBar color={'white'} />

            <div className="titleHolder">
                <h1 className="noMatchTitle">
                    404
                </h1>
            </div>
        </div>
    );
};