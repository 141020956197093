import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';

import { TopBar, Footer } from '../../components'

import { userActions } from '../../_actions';

import './HomePage.css';

class HomePage extends Component {

    state = {
      divClass: 'pageContent'
    };

    componentDidMount(){
        this.fadeIn();
    }

    fadeIn = () => {
        setTimeout(() => {
            this.setState({
                divClass: 'pageContent active'
            })
        }, 500);
    };

    render() {
        const { history } = this.props;
        return (
            <div className="">
                <MetaTags>
                    <title>Entertainment Intelligence | Home Page</title>
                    <meta name="description" content="Entertainment Intelligence" />
                    <meta property="og:title" content="Entertainment Intelligence" />
                    <meta property="og:image" content="./og-ei.jpg" />
                </MetaTags>
                <TopBar color={'white'} history={history} />

                <div className={this.state.divClass}>
                    <h1 className="pageContentTitle">
                        Entertainment <br/>Intelligence
                    </h1>
                </div>
                <Footer color={'white'} history={history} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return {
        user,
        users
    };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };