import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { contactUsFormActions } from '../../_actions/';

import './Recaptcha.css';

const RECAPTCHA_SITE_KEY = '6Ld_gvkUAAAAAEN2c3myjc2H9C_6EwiPDgENT5GJ';

function Recaptcha(props) {
    const { handleLoaded } = props;

    useEffect(() => {
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`
        script.addEventListener("load", handleLoaded)
        document.body.appendChild(script)
    }, [])

    return (
        <div
            className="g-recaptcha"
            data-sitekey={RECAPTCHA_SITE_KEY}
            data-size="invisible"
        />
    )
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  checkReCaptcha: (token) => dispatch(contactUsFormActions.checkCaptcha(token))
});

export default connect(
      mapStateToProps,
      mapDispatchToProps,
    )(Recaptcha);
