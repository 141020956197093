import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { TopBar } from '../../components';

import './PrivacyPage.css';

class PrivacyPage extends Component {

    state = {
        divClass: 'termsAndPrivacyContent',
    };

    componentDidMount(){
        this.fadeIn();
    }

    fadeIn = () => {
        setTimeout(() => {
            this.setState({
                divClass: 'termsAndPrivacyContent active'
            })
        }, 500);
    };

    render() {
        const { history } = this.props;
        return (
            <div className={this.state.divClass}>
                <MetaTags>
                    <title>Entertainment Intelligence | Privacy Page</title>
                    <meta name="description" content="Entertainment Intelligence" />
                    <meta property="og:title" content="Entertainment Intelligence" />
                    <meta property="og:image" content="./og-ei.jpg" />
                </MetaTags>
                <TopBar history={history} color={'black'} />
                <div className="privacyTextHolder">
                    <h2>PRIVACY POLICY</h2>
                    <p className="privacyText">
                        Last updated November 11, 2024
                    </p>
                    <p class="privacyText"> 
                        This Privacy Notice for Entertainment Intelligence Ltd (doing business as Ei) ('we', 'us', or 'our'), describes how and why we might access, collect, store, use, and/or share ('process') your personal information when you use our services ('Services'), including when you:
                    </p>
                    <p class="privacyText">
                        <ul>
                            <li>Visit our website at <a href="https://enterlytics.entertainment-intelligence.com" target="_blank" noopener noreferrer>https://enterlytics.entertainment-intelligence.com</a>, <a href="https://entertainment-intelligence.com/" target="_blank" noopener noreferrer>https://entertainment-intelligence.com/</a> or any website of ours that links to this Privacy Notice</li>
                            <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                        </ul>
                    </p>
                    <p class="privacyText">
                        <strong>Questions or concerns?</strong> Reading this Privacy Notice will help you understand your privacy rights and choices. We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href="mailto:info@entertainment-intelligence.com">info@entertainment-intelligence.com</a>.
                    </p>
                    <p class="privacyText title" id="summary-of-key-points">
                        <strong>SUMMARY OF KEY POINTS</strong>
                    </p>
                    <p class="privacyText">
                        This summary provides key points from our Privacy Notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.
                    </p>
                    <p class="privacyText">
                        <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose from the appropriate heading below.
                    </p>
                    <p class="privacyText">
                        <strong>Do we process any sensitive personal information?</strong> Some of the information may be considered 'special' or 'sensitive' in certain jurisdictions, for example your racial or ethnic origins, sexual orientation, and religious beliefs. We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law. Learn more about sensitive information we process below.
                    </p>
                    <p class="privacyText">
                        <strong>Do we collect any information from third parties?</strong> We do not collect any information from third parties.
                    </p>
                    <p class="privacyText">
                        <strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information below.
                    </p>
                    <p class="privacyText">
                        <strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information.
                    </p>
                    <p class="privacyText">
                        <strong>How do we keep your information safe?</strong> We have adequate organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe below.
                    </p>
                    <p class="privacyText">
                        <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights below.
                    </p>
                    <p class="privacyText">
                        <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by visiting 
                        <a href="https://enterlytics.entertainment-intelligence.com/" target="_blank" noopener noreferrer>https://enterlytics.entertainment-intelligence.com/</a>, 
                        or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
                    </p>

                    <p class="privacyText">
                        <strong>Use of the YouTube Data API.</strong> You or your authorised agent have entered into an Entertainment Intelligence 
                        Service Agreement with us (the “Service Agreement”), which permits us to tap into data feeds regarding the master 
                        recordings and videos owned or controlled by you in order to fulfil our obligations to you under the Service Agreement 
                        to provide you and your stakeholders with relevant metrics. You are reading this privacy policy regarding YouTube because 
                        you have added a YouTube Channel ID as one of the channels that you wish Ei to track for you. All data that we collect 
                        from YouTube is provided by Google, so you may also want to read the Google Privacy Policy at 
                        <a href="http://www.google.com/policies/privacy" target="_blank" noopener noreferrer>http://www.google.com/policies/privacy</a>.
                    </p>

                    <p class="privacyText">
                        Want to learn more about what we do with any information we collect? Review the Privacy Notice in full.
                    </p>
                    <div class="toc-container">

                        <h3 className='privacyText title' id="table-of-contents"><strong>Table of Contents</strong></h3>

                        <ol class="toc-list">
                            <li><a href="#summary-of-key-points">Summary of Key Points</a></li>
                            <li><a href="#table-of-contents">Table of Contents</a></li>
                            <li><a href="#definitions">Definitions</a></li>
                            <li><a href="#what-information-do-we-collect">What Information Do We Collect?</a>
                                <ul>
                                    <li><a href="#personal-information-you-disclose-to-us">Personal Information You Disclose to Us</a></li>
                                    <li><a href="#information-automatically-collected">Information Automatically Collected</a></li>
                                </ul>
                            </li>
                            <li><a href="#how-do-we-process-your-information">How Do We Process Your Information?</a></li>
                            <li><a href="#what-legal-bases-do-we-rely-on">What Legal Bases Do We Rely on to Process Your Information?</a></li>
                            <li><a href="#when-and-with-whom-do-we-share-your-information">When and With Whom Do We Share Your Personal Information?</a></li>
                            <li><a href="#do-we-use-cookies">Do We Use Cookies and Other Tracking Technologies?</a></li>
                            <li><a href="#google-analytics">Google Analytics</a></li>
                            <li><a href="#how-long-do-we-keep-your-information">How Long Do We Keep Your Information?</a></li>
                            <li><a href="#how-do-we-keep-your-information-safe">How Do We Keep Your Information Safe?</a></li>
                            <li><a href="#do-we-collect-information-from-minors">Do We Collect Information from Minors?</a></li>
                            <li><a href="#what-are-your-privacy-rights">What Are Your Privacy Rights?</a>
                                <ul>
                                    <li><a href="#account-information">Account Information</a></li>
                                    <li><a href="#controls-for-do-not-track-features">Controls for Do-Not-Track Features</a></li>
                                    <li><a href="#us-specific-privacy-rights">Do United States Residents Have Specific Privacy Rights?</a></li>
                                </ul>
                            </li>
                            <li><a href="#categories-of-personal-information-we-collect">Categories of Personal Information We Collect</a></li>
                            <li><a href="#sources-of-personal-information">Sources of Personal Information</a></li>
                            <li><a href="#how-we-use-and-share-personal-information">How We Use and Share Personal Information</a></li>
                            <li><a href="#will-your-information-be-shared">Will Your Information Be Shared With Anyone Else?</a></li>
                            <li><a href="#your-rights">Your Rights</a>
                                <ul>
                                    <li><a href="#how-to-exercise-your-rights">How to Exercise Your Rights</a></li>
                                    <li><a href="#request-verification">Request Verification</a></li>
                                    <li><a href="#appeals">Appeals</a></li>
                                </ul>
                            </li>
                            <li><a href="#california-shine-the-light-law">California 'Shine The Light' Law</a></li>
                            <li><a href="#do-other-regions-have-specific-privacy-rights">Do Other Regions Have Specific Privacy Rights?</a>
                                <ul>
                                    <li><a href="#australia-and-new-zealand">Australia and New Zealand</a></li>
                                </ul>
                            </li>
                            <li><a href="#do-we-make-updates-to-this-notice">Do We Make Updates to This Notice?</a></li>
                            <li><a href="#how-can-you-review-update-delete-data">How Can You Review, Update, or Delete the Data We Collect From You?</a></li>
                        </ol>
                    </div>

                    <h3 class="privacyText title" id="definitions"><strong>Definitions</strong></h3>
                    <p class="privacyText">
                        Definitions. As used in this Privacy Policy and Terms of Service:
                        <ul class="abc">
                            <li>
                                “Personal Data” means any information relating to a natural person that may identify 
                                that person directly or indirectly, in particular by reference to an identifier such as a name, 
                                an identification number, location data, or other relevant information. “Personal Data” does not apply 
                                to business entities but does apply to individual “Stakeholders” (as defined below).
                            </li>
                            <li>
                                “Client” means a business entity that has entered into a Services Agreement with 
                                Entertainment Intelligence (“Ei”), pursuant to which Ei provides the Client and its designated Stakeholders 
                                access to Ei’s Dashboard and services.
                            </li>
                            <li>
                                “Stakeholder(s)” means the Client’s employees and independent contractors, any label 
                                distributed by the Client, artists whose recordings are released on such labels, managers of such artists, 
                                and others who may have a financial or ownership interest in master recordings and musical compositions 
                                released on such labels.
                            </li>
                        </ul>
                    </p>

                    <h3 class="privacyText title" id="what-information-do-we-collect"><strong>What Information Do We Collect?</strong></h3>
                    <h3 class="privacyText title" id="personal-information-you-disclose-to-us">Personal Information You Disclose to Us</h3>
                    <p class="privacyText italic">
                        <strong>In Short:</strong> We collect personal information that you provide to us and API data you agree to grant us access to.
                    </p>
                    <p class="privacyText">
                        We collect personal information that you voluntarily provide to us when you register on the Services, 
                        express an interest in obtaining information about us or our products and Services, when you participate 
                        in activities on the Services, or otherwise when you contact us.
                    </p>
                    <p class="privacyText">
                        Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include 
                        the following:
                    </p>
                    <p class="privacyText">
                        <ul>
                            <li>Names</li>
                            <li>Email addresses</li>
                            <li>Usernames</li>
                            <li>Passwords</li>
                            <li>Contact preferences</li>
                            <li>Your IP address when you log in</li>
                            <li>
                                We may also ask for your location information (city, country, and time zone), although we are not currently 
                                requesting that information.
                            </li>
                            <li>Communications between you and us, e.g., support tickets.</li>
                        </ul>
                    </p>
                    <p class="privacyText">
                        All personal information that you provide to us must be true, complete, and accurate, and you must notify us of 
                        any changes to such personal information.
                    </p>
                    <p class="privacyText">
                        Our internal API uses YouTube API Services – specifically YouTube API Data. We do not have any “write” capability on YouTube (e.g., uploading videos). We do not collect any Personal Information (as that term is understood under applicable data protection laws). Each day, we collect only data related to videos that have been added to your channel, e.g., the number of views for a video, the number of videos in a playlist, the name of the artist, and the track title. We then process that data through our internal API to provide you with relevant metrics. We do not share any of this information with anyone but you and those of your stakeholders who you have expressly authorised to have log-in credentials to the Ei Dashboard.
                    </p>

                    <h3 class="privacyText title" id="information-automatically-collected">Information Automatically Collected</h3>
                    <p class="privacyText italic">
                        <strong>In Short:</strong> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics 
                        — is collected automatically when you visit our Services.
                    </p>
                    <p class="privacyText">
                        We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal 
                        your specific identity (like your name or contact information) but may include device and usage information, such as your 
                        IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, 
                        country, location, information about how and when you use our Services, and other technical information. This information 
                        is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
                    </p>
                    <p class="privacyText">
                        Like many businesses, we also collect information through cookies and similar technologies. You can find out more about 
                        this in our <a href="https://entertainment-intelligence.com/cookies">Cookie Notice</a>.
                    </p>
                    <p class="privacyText">
                        The information we collect includes:
                    </p>
                    <p class="privacyText">
                        <ul>
                        <li>
                            <strong>Log and Usage Data:</strong> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).
                        </li>
                        <li>
                            <strong>Device Data:</strong>  We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
                        </li>
                        <li>
                            <strong>Location Data:</strong> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.
                        </li>
                    </ul>
                    </p>
                    <h3 class="privacyText title" id="how-do-we-process-your-information"><strong>How Do We Process Your Information?</strong></h3>
                    <p class="privacyText italic">
                        <strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
                    </p>
                    <p class="privacyText">
                        We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
                    </p>
                    
                    <p class="privacyText">
                        <ul>
                        <li>
                            <strong>To facilitate account creation and authentication and otherwise manage user accounts.</strong> 
                            We may process your information so you can create and log in to your account, as well as keep your account in working order.
                        </li>
                        <li>
                            <strong>To deliver and facilitate delivery of services to the user.</strong> We may process your information to provide you with the requested service.
                        </li>
                        <li>
                            <strong>To respond to user inquiries/offer support to users.</strong> We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.
                        </li>
                        <li>
                            <strong>To send administrative information to you.</strong> We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.
                        </li>
                        <li>
                            <strong>To save or protect an individual’s vital interest.</strong> We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.
                        </li>
                    </ul>
                    </p>

                    <h3 class="privacyText title" id="what-legal-bases-do-we-rely-on"><strong>What Legal Bases Do We Rely on to Process Your Information?</strong></h3>
                    <p class="privacyText italic">
                        <strong>In Short:</strong> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.
                    </p>
                    <p class="privacyText underlined bold">
                        If you are located in the EU or UK, this section applies to you.
                    </p>
                    <p class="privacyText">
                        The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
                    </p>
                    <p class="privacyText">
                        <ul>
                        <li>
                            <strong>Consent.</strong> We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about withdrawing your consent below.
                        </li>
                        <li>
                            <strong>Performance of a Contract.</strong> We may process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.
                        </li>
                        <li>
                            <strong>Legal Obligations.</strong> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
                        </li>
                        <li>
                            <strong>Vital Interests.</strong> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
                        </li>
                    </ul>
                    </p>
                    <p class="privacyText">
                        In legal terms, we are generally the 'data controller' under European data protection laws of the personal information described in this Privacy Notice, since we determine the means and/or purposes of the data processing we perform. This Privacy Notice does not apply to the personal information we process as a 'data processor' on behalf of our customers. In those situations, the customer that we provide services to and with whom we have entered into a data processing agreement is the 'data controller' responsible for your personal information, and we merely process your information on their behalf in accordance with your instructions. If you want to know more about our customers' privacy practices, you should read their privacy policies and direct any questions you have to them.
                    </p>
                    <p class="privacyText underlined bold">
                        If you are located in Canada, this section applies to you.
                    </p>
                    <p class="privacyText">
                        We may process your information if you have given us specific permission (i.e. express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e. implied consent). You can withdraw your consent at any time, see below for details.
                    </p>
                    <p class="privacyText">
                        In some exceptional cases, we may be legally permitted under applicable law to process your information without your 
                        consent, including, for example:
                    </p>
                    <p class="privacyText">
                        <ul>
                        <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
                        <li>For investigations and fraud detection and prevention</li>
                        <li>For business transactions provided certain conditions are met</li>
                        <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
                        <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
                        <li>If we have reasonable grounds to believe an individual has been, is, or may be a victim of financial abuse</li>
                        <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</li>
                        <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</li>
                        <li>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</li>
                        <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
                        <li>If the information is publicly available and is specified by the regulations</li>
                    </ul>
                    </p>
                    <h3 class="privacyText title" id="when-and-with-whom-do-we-share-your-information"><strong>When and With Whom Do We Share Your Personal Information?</strong></h3>
                    <p class="privacyText italic">
                        <strong>In Short:</strong> We may share information in specific situations described in this section and/or with the following third parties.
                    </p>
                    <p class="privacyText">
                        We may need to share your personal information in the following situations:
                    </p>
                    <p class="privacyText">
                        <ul>
                        <li>
                        Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. In this case the buyer or transferee will have to honour the commitments we have made in this Privacy Policy.
                        </li>
                        <li>We do not share your Personal Data with anyone. However, if you are a Stakeholder, we may verify with the Client that you are an authorised user under the Client’s Services Agreement with us.
                        </li>
                        <li>
                        We may access, preserve and share your Personal Data in response to a legal request (like a search warrant, court order or subpoena) if we have a good faith belief that the law requires us to do so.
                        </li>
                    </ul>
                    </p>

                    <h3 class="privacyText title" id="do-we-use-cookies"><strong>Do We Use Cookies and Other Tracking Technologies?</strong></h3>
                    <p class="privacyText italic">
                        <strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.
                    </p>
                    <p class="privacyText">
                        We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.
                    </p>
                    <p class="privacyText">
                        We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.
                    </p>
                    <p class="privacyText">
                        To the extent these online tracking technologies are deemed to be a 'sale'/'sharing' (which includes targeted advertising, as defined under the applicable laws) under applicable US state laws, you can opt out of these online tracking technologies by submitting a request as described below under section <a href="#do-united-states-residents-have-specific-privacy-rights">'DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?'</a>
                    </p>
                    <p class="privacyText">
                        Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice: 
                        <a href="https://entertainment-intelligence.com/cookies"  target="_blank" noreferrer noopener>https://entertainment-intelligence.com/cookies.</a>.
                    </p>

                    <h3 class="privacyText title" id="google-analytics"><strong>Google Analytics</strong></h3>
                    <p class="privacyText">
                        We may share your information with Google Analytics to track and analyse the use of the Services. The Google Analytics Advertising Features that we may use include: Google Analytics Demographics and Interests Reporting. To opt out of being tracked by Google Analytics across the Services, visit <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" noreferrer noopener>https://tools.google.com/dlpage/gaoptout</a>. You can opt out of Google Analytics Advertising Features through Ads Settings from <a href="https://adssettings.google.com" target="_blank" noreferrer noopener>https://adssettings.google.com</a> and Ad Settings for mobile apps. Other opt out means include <a href="http://optout.networkadvertising.org/"  target="_blank" noreferrer noopener>http://optout.networkadvertising.org/</a> and <a href="http://www.networkadvertising.org/mobile-choice"  target="_blank" noreferrer noopener>http://www.networkadvertising.org/mobile-choice</a>. For more information on the privacy practices of Google, please visit the Google Privacy & Terms page here <a href="https://policies.google.com/privacy"  target="_blank" noreferrer noopener>https://policies.google.com/privacy</a>
                    </p>

                    <h3 class="privacyText title" id="how-long-do-we-keep-your-information"><strong>How Long Do We Keep Your Information?</strong></h3>
                    <p class="privacyText italic">
                        <strong>In Short:</strong> We keep your information for as long as necessary to fulfil the purposes outlined in this Privacy Notice unless otherwise required by law.
                    </p>
                    <p class="privacyText">
                        We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us to keep your personal information for longer than three (3) months past the termination of the user's account.
                    </p>
                    <p class="privacyText">
                        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                    </p>

                    <h3 class="privacyText title" id="how-do-we-keep-your-information-safe"><strong>How Do We Keep Your Information Safe?</strong></h3>
                    <p class="privacyText italic">
                        <strong>In Short:</strong> We aim to protect your personal information through a system of organizational and technical security measures.
                    </p>
                    <p class="privacyText">The personal data that we collect is stored in the United Kingdom and elsewhere where our servers may be backed up.</p>
                    <p class="privacyText">
                        We use commercially reasonable safeguards to help keep personal data secure. However, you are also responsible at all times for controlling access to emails between you and us, and ensuring that your password remains secure. We are not responsible for the functionality, privacy, or security measures of any other person or organisation.
                    </p>
                    <p class="privacyText">
                        We implement physical, business and technical security measures to protect all such information. Your password is encrypted. We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
                    </p>
                    <p className='privacyText'>
                        Once we have obtained data, we store it until you request deletion of it by emailing us at <a href="mailto:info@entertainment-intelligence.com">info@entertainment-intelligence.com</a> or until termination or expiration of the Service Agreement. You can revoke our access to future data by using your content-management system on our Dashboard, or via the Google security settings page at <a href="https://security.google.com/settings/security/permissions" target='_blank' noreferrer noopener>https://security.google.com/settings/security/permissions</a>. As provided in the Service Agreement, we reserve the right to create anonymised data from any data we have obtained from data feeds (including YouTube API Data), and to retain that anonymised data indefinitely.
                    </p>

                    <h3 class="privacyText title" id="do-we-collect-information-from-minors"><strong>Do We Collect Information from Minors?</strong></h3>
                    <p class="privacyText italic">
                        <strong>In Short:</strong> We do not knowingly collect data from or market to children under 18 years of age.
                    </p>
                    <p class="privacyText">
                        We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <a href="mailto:info@entertainment-intelligence.com">info@entertainment-intelligence.com</a>.
                    </p>
                    <h3 class="privacyText title" id="what-are-your-privacy-rights"><strong>What Are Your Privacy Rights?</strong></h3>
                    <p class="privacyText italic">
                        <strong>In Short:</strong> Depending on your state of residence in the US or in some regions, such as the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.
                    </p>
                    <p class="privacyText">
                        In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section <a href="#how-can-you-contact-us-about-this-notice">'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</a> below.
                    </p>
                    <p class="privacyText">
                        We will consider and act upon any request in accordance with applicable data protection laws.
                    </p>
                    <p class="privacyText">
                        If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target='_blank' noreferrer noopener>Member State data protection authority</a> or <a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/" target='_blank' noreferrer noopener>UK data protection authority</a>.
                    </p>
                    <p class="privacyText">
                        If you are located in Switzerland, you may contact the <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target='_blank' noreferrer noopener>Federal Data Protection and Information Commissioner</a>.
                    </p>

                    <p class="privacyText">
                        <strong>Withdrawing Your Consent:</strong> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section <a href="#how-can-you-contact-us-about-this-notice">'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</a> below or updating your preferences.
                    </p>
                    <p class="privacyText">
                    However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
                    </p>

                    <p class="privacyText">
                        <strong>Opting Out of Marketing and Promotional Communications:</strong> You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section <a href="#how-can-you-contact-us-about-this-notice">'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</a> below. 
                        below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.
                    </p>

                    <h3 class="privacyText title" id="account-information">Account Information</h3>
                    <p class="privacyText">
                    If you would at any time like to review or change the information in your account or terminate your account, you can log in to your account settings and update your user account.
                    </p>
                    <p class="privacyText">
                    Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.

                    </p>

                    <h3 class="privacyText title" id="cookies-and-similar-technologies">Cookies and Similar Technologies</h3>
                    <p class="privacyText">
                    Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. For further information, please see our Cookie Notice: <a href="https://entertainment-intelligence.com/cookies" target="_blank" noreferrer noopener>https://entertainment-intelligence.com/cookies</a>.
                    </p>
                    <p class="privacyText">
                        If you have questions or comments about your privacy rights, you may email us at <a href="mailto:info@entertainment-intelligence.com">info@entertainment-intelligence.com</a>.
                    </p>

                    <h3 class="privacyText title" id="controls-for-do-not-track-features">Controls for Do-Not-Track Features</h3>
                    <p class="privacyText">
                        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Notice.
                    </p>
                    <p class="privacyText">
                        California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognising or honouring DNT signals, we do not respond to them at this time.
                    </p>

                    <h3 class="privacyText title" id="do-united-states-residents-have-specific-privacy-rights"><strong>Do United States Residents Have Specific Privacy Rights?</strong></h3>
                    <p class="privacyText italic">
                        <strong>In Short:</strong> If you are a resident of California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Minnesota, Montana, Nebraska, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to request access to and receive details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. More information is provided below.
                    </p>
                    <h3 class="privacyText title" id="categories-of-personal-information-we-collect"><strong>Categories of Personal Information We Collect</strong></h3>
                    <p class="privacyText">
                        We have collected the following categories of personal information in the past twelve (12) months:
                    </p>
                    <p class="privacyText table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Examples Collected</th>
                                    <th>Collected</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>A. Identifiers</td>
                                    <td>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, 
                                    online identifier, Internet Protocol address, email address, and account name</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>B. Personal information as defined in the California Customer Records statute</td>
                                    <td>Name, contact information, education, employment, employment history, and financial information</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>C. Protected classification characteristics under state or federal law</td>
                                    <td>Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>D. Commercial information</td>
                                    <td>Transaction information, purchase history, financial details, and payment information</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>E. Biometric information</td>
                                    <td>Fingerprints and voiceprints</td>
                                    <td>Np</td>
                                </tr>
                                <tr>
                                    <td>F. Internet or other similar network activity</td>
                                    <td>Browsing history, search history, online behaviour, interest data, and interactions with our and other websites, applications, systems, and advertisements</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>G. Geolocation data</td>
                                    <td>Device location</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>H. Audio, electronic, sensory, or similar information</td>
                                    <td>Images and audio, video or call recordings created in connection with our business activities</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>I. Professional or employment-related information</td>
                                    <td>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>J. Education Information</td>
                                    <td>Student records and directory information</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>K. Inferences drawn from collected personal information</td>
                                    <td>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>L. Sensitive personal information</td>
                                    <td>Account login information, contents of email or text messages and precise geolocation</td>
                                    <td>Yes</td>
                                </tr>
                            </tbody>
                        </table>
                    </p>
                    <p class="privacyText">
                        We only collect sensitive personal information, as defined by applicable privacy laws or the purposes allowed by law or with your consent. Sensitive personal information may be used, or disclosed to a service provider or contractor, for additional, specified purposes. You may have the right to limit the use or disclosure of your sensitive personal information. We do not collect or process sensitive personal information for the purpose of inferring characteristics about you.
                    </p>
                    <p class="privacyText">
                        We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
                    </p>
                    <p class="privacyText">
                        <ul>
                            <li>Receiving help through our customer support channels;</li>
                            <li>Participation in customer surveys or contests; and</li>
                            <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
                        </ul>
                    </p>
                    <p class="privacyText">
                        We will use and retain the collected personal information as needed to provide the Services or for:
                    </p>
                    <p class="privacyText">
                        <ul>
                            <li>Category A - As long as the user has an account with us</li>
                            <li>Category B - As long as the user has an account with us</li>
                            <li>Category G - As long as the user has an account with us
                            </li>
                            <li>Category H - As long as the user has an account with us
                            </li>
                            <li>Category L - As long as the user has an account with us
                            </li>
                        </ul>
                    </p>

                    <h3 class="privacyText title" id="sources-of-personal-information">Sources of Personal Information</h3>
                    <p class="privacyText">
                        Learn more about the sources of personal information we collect in <a href="#what-information-do-we-collect">‘WHAT INFORMATION DO WE COLLECT?’</a>.
                    </p>

                    <h3 class="privacyText title" id="how-we-use-and-share-personal-information">How We Use and Share Personal Information</h3>
                    <p class="privacyText">
                        Learn more about how we use your personal information in the section, <a href="#how-do-we-process-your-information">‘HOW DO WE PROCESS YOUR INFORMATION?’</a>
                    </p>

                    <h3 class="privacyText title" id="will-your-information-be-shared">Will Your Information Be Shared With Anyone Else?</h3>
                    <p class="privacyText">
                        We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information in the section, <a href="#when-and-with-whom-do-we-share-your-information">‘WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?’</a>
                    </p>
                    <p class="privacyText">
                        We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be the  'selling' of your personal information.
                    </p>
                    <p class="privacyText">              
                        We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
                    </p>

                    <h3 class="privacyText title" id="your-rights"><strong>Your Rights</strong></h3>
                    <p class="privacyText">
                        You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:
                    </p>
                    <p class="privacyText">
                        <ul>
                        <li>Right to know whether or not we are processing your personal data;</li>
                        <li>Right to access your personal data;</li>
                        <li>Right to correct inaccuracies in your personal data;</li>
                        <li>Right to request the deletion of your personal data;</li>
                        <li>Right to obtain a copy of the personal data you previously shared with us;</li>
                        <li>Right to non-discrimination for exercising your rights;</li>
                        <li>Right to opt out of the processing of your personal data if it is used for targeted advertising (or sharing as defined under California’s privacy law), the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ('profiling')</li>
                    </ul>
                    </p>
                    
                    <p class="privacyText">
                        Depending upon the state where you live, you may also have the following rights:
                        <ul>

                            <li>Right to access the categories of personal data being processed (as permitted by applicable law, including Minnesota’s privacy law)</li>
                            <li>Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including California's and Delaware's privacy law)</li>
                            <li>Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Minnesota's and Oregon's privacy law)</li>
                            <li>Right to review, understand, question, and correct how personal data has been profiled (as permitted by applicable law, including Minnesota’s privacy law)</li>
                            <li>Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including California’s privacy law)</li>
                            <li>Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida’s privacy law)</li>
                        </ul>
                    </p>

                    <h3 class="privacyText title" id="how-to-exercise-your-rights">How to Exercise Your Rights</h3>
                    <p class="privacyText">
                        To exercise these rights, you can contact us by visiting <a href="https://enterlytics.entertainment-intelligence.com/" target='_blank' noreferrer noopener>https://enterlytics.entertainment-intelligence.com/</a>, 
                        by emailing us at <a href="mailto:info@entertainment-intelligence.com">info@entertainment-intelligence.com</a>, or by referring to the contact details at the bottom of this document.
                    </p>
                    <p class="privacyText">
                    Under certain US state data protection laws, you can designate an authorised agent to make a request on your behalf. We may deny a request from an authorised agent that does not submit proof that they have been validly authorised to act on your behalf in accordance with applicable laws.
                    </p>

                    <h3 class="privacyText title" id="request-verification">Request Verification</h3>
                    <p class="privacyText">
                        Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information 
                        in our system. We will only use personal information provided in your request to verify your identity or authority to make the request.
                        However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information 
                        for the purposes of verifying your identity and for security or fraud-prevention purposes.
                    </p>
                    <p class="privacyText">
                        If you submit the request through an authorised agent, we may need to collect additional information to verify your identity before processing 
                        your request, and the agent will need to provide a written and signed permission from you to submit such a request on your behalf.
                    </p>

                    <h3 class="privacyText title" id="appeals">Appeals</h3>
                    <p class="privacyText">
                        Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing us 
                        at <a href="mailto:info@entertainment-intelligence.com">info@entertainment-intelligence.com</a>. We will inform you in writing of any action 
                        taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, 
                        you may submit a complaint to your state attorney general.
                    </p>

                    <h3 class="privacyText title" id="california-shine-the-light-law">California 'Shine The Light' Law</h3>
                    <p class="privacyText">
                        California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us by using the contact details provided in the section <a href="#how-can-you-contact-us-about-this-notice">'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</a> below.
                    </p>

                    <h3 class="privacyText title" id="do-other-regions-have-specific-privacy-rights">Do Other Regions Have Specific Privacy Rights?</h3>
                    <p class="privacyText italic">
                        <strong>In Short:</strong> You may have additional rights based on the country you reside in.
                    </p>

                    <h3 class="privacyText title" id="australia-and-new-zealand"><strong>Australia and New Zealand</strong></h3>
                    <p class="privacyText">
                        We collect and process your personal information under the obligations and conditions set by Australia's Privacy Act 1988 and New Zealand's Privacy Act 2020 (Privacy Act).
                    </p>
                    <p class="privacyText">
                        This Privacy Notice satisfies the notice requirements defined in both Privacy Acts, in particular: what personal information we collect from you, from which sources, for which purposes, and other recipients of your personal information.
                    </p>
                    <p class="privacyText">
                        If you do not wish to provide the personal information necessary to fulfil their applicable purpose, it may affect our ability to provide our services, in particular:
                    </p>
                    <p class="privacyText">
                        <ul>
                        <li>offer you the products or services that you want</li>
                        <li>respond to or help with your requests</li>
                        <li>manage your account with us</li>
                        <li>confirm your identity and protect your account</li>
                    </ul>
                    </p>
                    <p class="privacyText">
                        At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the contact details provided in the section <a href="#how-can-you-review-update-delete-data">'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?'</a>
                    </p>
                    <p class="privacyText">
                        If you believe we are unlawfully processing your personal information, you have the right to submit a complaint about a breach of the Australian 
                        Privacy Principles to the <a href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us" target="_blank" noopener noreferrer>Office of the Australian Information Commissioner</a> 
                        and a breach of New Zealand's Privacy Principles to the <a href="https://www.privacy.org.nz/your-rights/making-a-complaint/" target="_blank" noopener noreferrer>Office of New Zealand Privacy Commissioner</a>.
                    </p>

                    <h3 class="privacyText title" id="do-we-make-updates-to-this-notice"><strong>Do We Make Updates to This Notice?</strong></h3>
                    <p class="privacyText italic">
                        <strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.
                    </p>
                    <p class="privacyText">
                        We may update this Privacy Notice from time to time. The updated version will be indicated by an updated 'Revised' date at the top of this Privacy Notice. If we make material changes to this Privacy Notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this Privacy Notice frequently to be informed of how we are protecting your information.
                    </p>

                    <h3 class="privacyText title" id="how-can-you-contact-us-about-this-notice">How Can You Contact Us About This Notice?</h3>
                    <p class="privacyText">
                        If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO) by email at <a href="mailto:info@entertainment-intelligence.com">info@entertainment-intelligence.com</a>, 
                        or contact us by post at:
                    </p>
                    <address class="privacyText">
                        <div>Entertainment Intelligence Ltd</div>
                        <div>Data Protection Officer</div>
                        <div>6b Parkway, Porters Wood</div>
                        <div>St Albans, England AL3 6PA</div>
                        <div>United Kingdom</div>
                    </address>
                    <p class="privacyText">
                        If you are a resident in the United Kingdom, we are the 'data controller' of your personal information. We have appointed Greg Delaney to be our representative in the UK. You can contact them directly regarding our processing of your information, by email at <a href="mailto:info@entertainment-intelligence.com">info@entertainment-intelligence.com</a>, by visiting <a href="https://entertainment-intelligence.com/" target="_blank" noopener noreferrer>https://entertainment-intelligence.com/</a>, or by post to:
                    </p>
                    <address class="privacyText">
                        <div>6B Parkway</div>
                        <div>Porters Wood</div>
                        <div>St Albans, Herts AL3 6PA</div>
                        <div>England</div>
                    </address>

                    <h3 class="privacyText title" id="how-can-you-review-update-delete-data"><strong>How Can You Review, Update, or Delete the Data We Collect From You?</strong></h3>
                    <p class="privacyText">
                        Based on the applicable laws of your country or state of residence in the US, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law.
                    </p>
                    <p class="privacyText">
                        If our Client terminates its relationship with us, the Client’s information, including any Personal Data of Stakeholders is deleted, following which none of the foregoing will have access to our Dashboard or our services.
                    </p>
                    <p class="privacyText">
                        To request to review, update, or delete your personal information, please visit: 
                        <a href="https://enterlytics.entertainment-intelligence.com/" target="_blank" noopener noreferrer>https://enterlytics.entertainment-intelligence.com/</a> 
                        or you may send us an email at <a href="mailto:info@entertainment-intelligence.com">info@entertainment-intelligence.com</a> asking us to remove your Personal Data from our servers, 
                        following which you will no longer have access to our Dashboard or our services.
                    </p>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedPrivacyPage = connect(mapStateToProps)(PrivacyPage);
export {connectedPrivacyPage as PrivacyPage};
