import { clientsConstants } from '../_constants';

export function clients(state = {}, action) {
  switch (action.type) {
    case clientsConstants.GETALL_CLIENTS_REQUEST:
      return {
        loading: true
      };
    case clientsConstants.GETALL_CLIENTS_SUCCESS:
      return {
        items: action.clients
      };
    case clientsConstants.GETALL_CLIENTS_FAILURE:
      return {
        error: action.error
      };


    default:
      return state
  }
}