import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { clients } from './clients.reducer';
import { contactUsForm } from './contactUsForm.reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  clients,
  contactUsForm,
});

export default rootReducer;