import { contactUsFormConstants } from '../_constants';

const initialState = {
    recaptcha: false,
    recaptchaInProgress: false,
    recaptchaError: false,
};

export function contactUsForm(state = initialState, action) {
  switch (action.type) {
    case contactUsFormConstants.RECAPTCHA_REQUEST:
        return { ...state, recaptchaInProgress: true,  recaptchaError: null };

    case contactUsFormConstants.RECAPTCHA_SUCCESS:
        return { ...state, recaptcha: action.payload, recaptchaInProgress: false };

    case contactUsFormConstants.RECAPTCHA_ERROR:
        return { ...state, recaptchaInProgress: false, recaptchaError: action.payload };

    default:
      return state
  }
}