export const userService = {
    register,
};

function register(email, company, token) {
    const url = 'https://orthus.entertainment-intelligence.com/api/v1/recaptcha'

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({'g-recaptcha-response-data': token, email: email, company: company}),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.json())
        .then((contents) => {
            return contents
        })
        .catch(e => {
            return (e);
        });
}
