import React, { Component } from 'react';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';

import { TopBar } from '../../components'

import './ServicesPage.css';

class ServicesPage extends Component {

    state = {
        divClass: 'servicesContent',
    };

    componentDidMount(){
        this.fadeIn();
    }

    fadeIn = () => {
        setTimeout(() => {
            this.setState({
                divClass: 'servicesContent active'
            })
        }, 500);
    };

    render() {
        const { history } = this.props;
        return (
            <div className={this.state.divClass}>
                <MetaTags>
                    <title>Entertainment Intelligence | Services Page</title>
                    <meta name="description" content="Entertainment Intelligence" />
                    <meta property="og:title" content="Entertainment Intelligence" />
                    <meta property="og:image" content="./og-ei.jpg" />
                </MetaTags>
                <TopBar history={history} color={'white'} />
                <ul className="servicesList">
                    <li className="servicesItem">
                        <h3 className="servicesTitle">
                            <span>TRENDS</span>
                            <span>&</span>
                            <span>ANALYTICS</span>
                        </h3>
                        <p className="servicesText">
                            At Ei we don't just display top-line trends and playlist data. You can drill down into the
                            granular data, for every aspect of your product campaign, across streaming platforms such as
                            Spotify, Apple, Deezer, Google, Pandora, etc. We clean and refine your data before
                            ingesting, allowing you to rest easy knowing what you are being shown is a true
                            representation of your catalogue’s performance. Furthermore, you can share this data with
                            anyone in your team, artist managers, PR, promoters and beyond.</p>
                    </li>
                    <li className="servicesItem">
                        <h3 className="servicesTitle">
                            <span>SOCIAL</span>
                            <span>MEDIA</span>
                        </h3>
                        <p className="servicesText">
                            Ei's social and video analytic tools allow you to fully understand your audience across
                            multiple networks. When this information is overlaid with sales and geographic data you can
                            for the first time gain previously unobtainable insights, informing every level of your
                            campaign.</p>
                    </li>
                    <li className="servicesItem">
                        <h3 className="servicesTitle">
                            <span>ADVANCED</span>
                            <span>INSIGHTS</span>
                        </h3>
                        <p className="servicesText">
                            We don't just show you numbers - we produce clean, precise and actionable insights, so you
                            and your team can make the most efficient and informed decisions to nurture and grow your
                            entire ecosystem. We ping catalog owners when we detect a &quot;heartbeat,&quot; such as an older
                            track, picking up steam. Ei helps music professionals do something retailers have be doing
                            for years, detailed cohort analysis.</p>
                    </li>
                    <li className="servicesItem">
                        <h3 className="servicesTitle">
                            <span>INDIE</span>
                            <span>BENCHMARKS</span>
                        </h3>
                        <p className="servicesText">
                            Ei anonymises and aggregates clients' data to generate high-quality insights about playlist
                            performance, source of streams, overindexing territories and listening behaviour, creating
                            industry benchmark for the independent sector that rival anything the major labels have to
                            offer.</p>
                    </li>
                </ul>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return {
        user,
        users
    };
}

const connectedServicesPage = connect(mapStateToProps)(ServicesPage);
export { connectedServicesPage as ServicesPage };
