import axios from 'axios';

export const clientsService = {
    getAll,
};


function getAll() {
    const requestOptions = {auth: {
        username: 'query_user',
        password: 'Ducksoap3'
    }}


    let proxy = "https://cors-anywhere.herokuapp.com/";
    return axios.get(proxy + 'https://data-middleware.herokuapp.com/api/v1/ei_clients/result.json', requestOptions)
}
