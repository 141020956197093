import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import './TopBar.css';

class TopBar extends Component {

    state = {
        currentPage: '',
        styles: {
            borderColor: 'white',
            color: 'white',
        }
    };

    componentDidMount(){
        const { location : { pathname } } = this.props.history;

        if(pathname === '/'){
            this.setState({
                currentPage: 'homePage',
                styles: {
                    borderColor: 'white',
                    color: 'white',
                }
            })
        }
        if(pathname === '/register'){
            this.setState({
                currentPage: 'registerPage',
                styles: {
                    borderColor: 'white',
                    color: 'white',
                }
            })
        }
        if(pathname === '/services'){
            this.setState({
                currentPage: 'servicesPage',
                styles: {
                    borderColor: 'black',
                    color: 'black',
                }
            })
        }
        if(pathname === '/clients'){
            this.setState({
                currentPage: 'clientsPage',
                styles: {
                    borderColor: 'black',
                    color: 'black',
                }
            })
        }
        if(pathname === '/about'){
            this.setState({
                currentPage: 'aboutPage',
                styles: {
                    borderColor: 'black',
                    color: 'black',
                }
            })
        }
        if(pathname === '/terms'){
            this.setState({
                currentPage: 'termsPage',
                styles: {
                    borderColor: 'black',
                    color: 'black',
                }
            })
        }
        if(pathname === '/privacy'){
            this.setState({
                currentPage: 'privacyPage',
                styles: {
                    borderColor: 'black',
                    color: 'black',
                }
            })
        }
        if(pathname === '/cookies'){
            this.setState({
                currentPage: 'cookiesPage',
                styles: {
                    borderColor: 'black',
                    color: 'black',
                }
            })
        }
        
    }

    render(){
        const { color } = this.state.styles;
        const { currentPage } = this.state;
        const styles = { color: color };

        return(
            <div className={`topBarRoot ${currentPage}`}>
                <nav className="topBarPageHolder">
                    <li className="topBarItem">
                        <NavLink
                            exact
                            style={styles}
                            activeClassName={'isActive'}
                            className={'topBarNavLink'}
                            to='/'>
                            Home
                        </NavLink>
                    </li>
                    <li className="topBarItem">
                        <NavLink
                            style={styles}
                            activeClassName={'isActive'}
                            className="topBarNavLink"
                            to='/about'>
                            About
                        </NavLink>
                    </li>
                    {/* <li className="topBarItem">
                        <NavLink
                            style={styles}
                            activeClassName={'isActive'}
                            className="topBarNavLink"
                            to='/services'>
                            Services
                        </NavLink>
                    </li> */}
                    {/* <li className="topBarItem">
                        <NavLink
                            style={styles}
                            activeClassName={'isActive'}
                            className="topBarNavLink"
                            to='/clients'>
                            Clients
                        </NavLink>
                    </li> */}
                    {/* <li className="topBarItem">
                        <NavLink
                            style={styles}
                            activeClassName={'isActive'}
                            className="topBarNavLink"
                            to='/terms-and-privacy'>
                            Terms &amp; Privacy
                        </NavLink>
                    </li> */}
                    
                </nav>

                <ul className="topBarAuthHolder">
                    <li className="topBarItem">
                        <NavLink
                            style={styles}
                            className="topBarNavLink"
                            activeClassName={'isActive'}
                            to="/register">
                            Contact
                        </NavLink>
                    </li>
                </ul>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return {
        user,
        users
    };
}

const connectedTopBar = connect(mapStateToProps)(withRouter(TopBar));
export { connectedTopBar as TopBar };
