import { clientsConstants } from '../_constants';
import { clientsService } from '../_services';
import { history } from '../_helpers';

export const clientsActions = {
    getAll
};


function getAll() {
    return dispatch => {
        dispatch(request());

        clientsService.getAll()
            .then(
                clients => {
                    console.log(clients)
                    return dispatch(success(clients))
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: clientsConstants.GETALL_CLIENTS_REQUEST } }
    function success(clients) { return { type: clientsConstants.GETALL_CLIENTS_SUCCESS, clients } }
    function failure(error) { return { type: clientsConstants.GETALL_CLIENTS_FAILURE, error } }
}