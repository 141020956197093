import { userConstants } from '../_constants';
import { userService } from '../_services';

export const userActions = {
    register
};

function register(user, company, token) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user, company, token)
            .then(
                response => {
                    if(response.status === 'not ok'){
                        dispatch(failure(response.details))
                    } else {
                        dispatch(success());
                    }
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, payload: error } }
}
