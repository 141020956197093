import { contactUsFormConstants } from '../_constants';
import { contactUsFormService } from '../_services';

function recaptchaRequest() { return { type: contactUsFormConstants.RECAPTCHA_REQUEST } }
function recaptchaSuccess(value) { return { type: contactUsFormConstants.RECAPTCHA_SUCCESS, payload: value, } }
function recaptchaError(error) { return { type: contactUsFormConstants.RECAPTCHA_ERROR, payload: error } }

function checkCaptcha(params) {
    return async (dispatch) => {
        if(params !== undefined){
            dispatch(recaptchaRequest());
            await contactUsFormService.checkCaptcha(params)
                .then(
                    value => {
                        if (value === true) {
                            dispatch(recaptchaSuccess(value));
                        } else (
                            dispatch(recaptchaError('Bad recaptcha'))
                        )
                    }
                )
                .catch(e => dispatch(recaptchaError(e)));
        }
    };
}

export const contactUsFormActions = {
    checkCaptcha
};
