import React from 'react';

export function updateValidators(validators, fieldName, value) {
    console.log(value)
    if(validators[fieldName]){
        validators[fieldName].errors = [];
        validators[fieldName].state = value;
        validators[fieldName].valid = true;
        validators[fieldName].rules.forEach((rule) => {
            if (rule.test instanceof RegExp) {
                if (!rule.test.test(value)) {
                    validators[fieldName].errors.push(rule.message);
                    validators[fieldName].valid = false;
                }
            } else if (typeof rule.test === 'function') {
                if (!rule.test(value)) {
                    validators[fieldName].errors.push(rule.message);
                    validators[fieldName].valid = false;
                }
            }
        });
    }
}

export function resetValidators(validators) {
    Object.keys(validators).forEach((fieldName) => {
        validators[fieldName].errors = [];
        validators[fieldName].state = '';
        validators[fieldName].valid = false;
    });
}

export function displayValidationErrors(validators, fieldName) {
    const validator = validators[fieldName];
    const result = '';
    if (validator && !validator.valid) {

        const errors = validator.errors.map((info, index) => {
            return <span className="has-error" key={index}>{ info }</span>;
        });

        return (
            <div className="error-holder">
                {errors}
            </div>
        );
    }
    return result;
}

export function isFormValid(validators) {
    let status = true;

    Object.keys(validators).forEach((field) => {
        if (!validators[field].valid) {
            status = false;
        }
    });

    return status;
}
